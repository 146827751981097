<template>
  <div class="home">
    <div class="topTop animated fadeInDown">
        <a>
            <div class="main_logo"></div>
        </a>
    </div>
    <MobileNav />
    <SideNav />
    <div id="mask"></div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import MobileNav from '@/components/common/MobileNav.vue'
import SideNav from '@/components/common/SideNav.vue'
import Footer from '@/components/common/Footer.vue'

export default {
  name: 'Home',
  components: {
    SideNav, MobileNav, Footer
  }
}
</script>
