import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {i18n} from './locales/i18n'
import axios from "./axios.js"
import common from "./assets/js/common"
import VueSimpleAlert from "vue-simple-alert";
 
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true 

export const eventBus = new Vue()
Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue)
Vue.use(common)
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
