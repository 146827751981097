<template>
  <div class="menu_left left animated fadeInLeft ani_delay03 motion"
       :class="{'hide-mobile-menu' : $store.state.mobile_menu===false, 'show-mobile-menu' : $store.state.mobile_menu===true}">
    <div class="close1" @click="openFn()">
      <i class="fal fa-times text-white fs-3"></i>
    </div>
    <div class="logo">
      <router-link to="/"></router-link>
    </div>
    <div class="topWrapP motion">
      <router-link to="/">
        <div class="tLogo animated fadeInLeft ani_delay02 motion"></div>
      </router-link>
      <div class="tGnb animated fadeInRight ani_delay05 motion">
        <div class="tUser"><i class="fal fa-user me-2"></i>{{ id }}</div>
        <button id="logout" class="d-inline rounded p-1 mx-2" @click="Logout()">
          <i class="fal fa-sign-out"></i>
        </button>
        <div class="tLang">
          <select v-model="$i18n.locale" @change="onLocaleChange($event)"
                  class="lang ng-untouched ng-pristine ng-valid m-0 form-select-sm">
            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`"
                    :value="locale">
              {{ locale === 'ko' ? '한국어' : 'English' }}
            </option>
          </select>
        </div>
        <a href="#">
          <div class="tMenu"></div>
        </a>
      </div>
    </div>
    <ul class="nav">
      <li v-for="(item, index) in menu" :key="index">
        <template v-if="item.submenus">
          <a class="menu tree" :class="{active: item.active}">
            <p @click="item.active = !item.active">
              {{item.name}}

              <i class="fal fa-chevron-down"></i>
            </p>

            <div class="submenus">
              <ul>
                <li v-for="sub in item.submenus">
                  <router-link class="menu" :to="sub.link">
                    <p>{{sub.name}}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </a>
        </template>

        <template v-else>
          <router-link :to="item.link" class="menu">
            <p>{{ item.name }}</p>
          </router-link>
        </template>
      </li>
      <li class='menu logout' @click="Logout()"><p>{{ $t('common.logout') }}</p></li>
    </ul>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      this.setTree()

      if (to.path != from.path) {
        this.$store.commit('open_menu', false)
      }
    }
  },
  created() {
    this.$i18n.locale = this.$store.state.locale

    this.setTree()
  },
  data() {
    return {
      mobileMenu: false,
      id: this.$store.state.id,
      menu: [
        {
          link: '/dashboard',
          name: this.$i18n.t('menu.home')
        }, {
          link: '/bot',
          name: this.$i18n.t('menu.bot')
        }, {
          name: this.$i18n.t('menu.wallet.name'),
          active: false,
          submenus: [
            {
              link: '/deposit',
              name: this.$i18n.t('menu.wallet.submenus.deposit'),
            }, {
              link: '/withdrawal',
              name: this.$i18n.t('menu.wallet.submenus.withdrawal'),
            }, {
              link: '/wallet/history',
              name: this.$i18n.t('menu.wallet.submenus.history'),
            }
          ]
        }, {
          link: '/profile',
          name: this.$i18n.t('menu.profile')
        }, {
          link: '/otp',
          name: this.$i18n.t('menu.otp')
        }, {
          link: '/notice',
          name: this.$i18n.t('common.notice')
        }, {
          link: '/inquiry',
          name: this.$i18n.t('menu.inquiry')
        },
      ]
    }
  },
  methods: {
    openFn() {
      this.$store.commit('open_menu', false)
    },
    Logout() {
      this.$store.dispatch('SETLOGOUT').then(
          () => {
            window.location.href = "/sign/login";
          }
      )
    },
    onLocaleChange(event) {
      this.$i18n.locale = event.target.value;
      this.$store.dispatch('changeLocale', event.target.value);
      window.location.reload(true);
    },
    setTree() {
      switch (this.$route.path) {
        case "/deposit":
        case "/withdrawal":
        case "/wallet/history":
          this.menu[2].active = true
          break

        default:
          this.menu[2].active = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-mobile-menu{
  right: 0;
}

.hide-mobile-menu{
  right: 1;
}

.menu_left {
  .nav {
    .logout {
      p {
        padding-left: 30px;
      }
    }

    .menu {
      &.tree {
        height: auto;
        overflow: hidden;

        > p {
          position: relative;
          z-index: 2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px 0 0;
          background-color: #06091B;

          i {
            transition: .3s transform ease;
          }
        }

        .submenus {
          display: grid;
          grid-template-rows: 0fr;
          overflow: hidden;
          transition: .3s all ease;

          ul {
            overflow: hidden;

            li {
              a {
                padding-left: 50px;
                font-size: 15px;
              }
            }
          }
        }

        &.active {
          p {
            i {
              transform: rotate(-180deg);
            }
          }

          .submenus {
            grid-template-rows: 1fr;
            margin-top: 1em;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1351px) {
  .menu_left {
    .nav {
      .menu {
        &.tree {
          > p {
            padding: 0 50px;
          }

          .submenus {
            ul {
              li {
                a {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
