<template>
  <div class="topWrap motion">
    <a>
      <div class="tLogo animated fadeInLeft ani_delay02 motion"></div>
    </a>
    <div class="tGnb animated fadeInRight ani_delay05 motion d-flex align-items-center">
      <div class="tUser">{{id}}</div>
      <div class="tOut">
        <i class="fal fa-sign-out text-white p-2"></i>
      </div>
      <div class="tLang">
        <select v-model="$i18n.locale" class="lang ng-untouched ng-pristine ng-valid">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
            {{ locale === 'ko' ? '한국어' : 'English'}}
          </option>
        </select>
      </div>
      <div class="tMenu" @click="openFn()">
        <i class="fal fa-bars"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
        id : this.$store.state.id
    }
  },
  created() {
    this.$i18n.locale = this.$store.state.locale
  },
  methods:{
    openFn(){
      this.$store.commit('open_menu', true)
    },
    onLocaleChange(event) {
      this.$i18n.locale = event.target.value;
      this.$store.dispatch('changeLocale', event.target.value);
      window.location.reload(true);
    }
  }
}
</script>
