export default {
  install(Vue) {
    Vue.prototype.$checkGrade = function(grade) {
      if (grade === '' || grade < 0 || grade > 7) {
        return this.$i18n.t('common.nonSale');
      }

      return this.$i18n.t('grade.grade' + grade);
    }

    Vue.prototype.$checkPlan = function(plan) {
      if (plan <= 0 || plan > 3) {
        return this.$i18n.t('common.nonSubscribe');
      }

      return "PLAN " + plan;
    }
  }
}
