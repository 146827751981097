<template>
    <div>
        <div class="clearfix"></div>
        <div class="footWrap">
            <div class="copymain animated fadeInRight"> © 2023 <strong>BITMASTER</strong> All rights reserved. </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>