import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Sign from '../views/Sign.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/dashboard',
    name: 'Home',
    component: Home,
    children:[
      {
        path:'/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/main/Dashboard.vue')
      },
      {
        path:'/otp',
        name: 'Otp',
        component: () => import(/* webpackChunkName: "otp" */ '../components/main/Otp.vue')
      },
      {
        path:'/sign-up',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "signup" */ '../components/main/Signup.vue')
      },
      {
        path:'/subscribe',
        name: 'Subscribe',
        component: () => import(/* webpackChunkName: "subscribe" */ '../components/main/Subscribe.vue')
      },
      {
        path:'/reward',
        name: 'Reward',
        component: () => import(/* webpackChunkName: "reward" */ '../components/main/Reward.vue')
      },
      {
        path:'/deposit',
        name: 'Deposit',
        component: () => import(/* webpackChunkName: "deposit" */ '../components/main/Deposit.vue')
      },
      {
        path:'/sponcer_list',
        name: 'Sponcer-list',
        component: () => import(/* webpackChunkName: "sponcer_list" */ '../components/main/SponcerList.vue')
      },
      {
        path:'/support_list',
        name: 'Support_list',
        component: () => import(/* webpackChunkName: "support_list" */ '../components/main/SupportList.vue')
      },
      
      {
        path:'/referrer_list',
        name: 'Referrer_list',
        component: () => import(/* webpackChunkName: "sponcer_list" */ '../components/main/ReferrerList.vue')
      },
      {
        path:'/income',
        name: 'Income',
        component: () => import(/* webpackChunkName: "income" */ '../components/main/Income.vue')
      },
      {
        path:'/withdrawal',
        name: 'Withdrawal',
        component: () => import(/* webpackChunkName: "withdrawal" */ '../components/main/Withdrawal.vue')
      },
      {
        path:'/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "history" */ '../components/main/History.vue')
      },
      {
        path:'/wallet/history',
        name: 'WalletHistory',
        component: () => import(/* webpackChunkName: "wallethistory" */ '../components/main/WalletHistory.vue')
      },
      {
        path:'/bot',
        name: 'Bot',
        component: () => import(/* webpackChunkName: "bot" */ '../components/main/Bot.vue')
      },
      {
        path:'/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Profile.vue')
      },
      {
        path:'/profile/chg-pw',
        name: 'Profile-ChgPw',
        component: () => import(/* webpackChunkName: "profile" */ '../components/profile/Profile-ChgPw.vue')
      },
      {
        path:'/notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "notice" */ '../components/main/Notice.vue')
      },
      {
        path:'/inquiry',
        name: 'Inquiry',
        component: () => import(/* webpackChunkName: "inquiry" */ '../components/inquiry/Inquiry.vue')
      },
      {
        path:'/inquiry/detail/:idx',
        name: 'InquiryDetail',
        component: () => import(/* webpackChunkName: "inquiry" */ '../components/inquiry/InquiryDetail.vue')
      },
      {
        path:'/inquiry/edit',
        name: 'InquiryEdit',
        component: () => import(/* webpackChunkName: "inquiry" */ '../components/inquiry/InquiryEdit.vue')
      },
    ]
  },
  {
    path: '/sign',
    name: 'Sign',
    component: Sign,
    redirect: '/sign/login',
    children:[
      {
        path:'/sign/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "sign" */ '../components/sign/Login.vue')
      },
      {
        path:'/sign/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "sign" */ '../components/sign/Signup.vue')
      },
      {
        path:'/sign/signup/:code',
        name: 'SignupCode',
        component: () => import(/* webpackChunkName: "sign" */ '../components/sign/Signup.vue')
      },
      {
        path:'/sign/confm-code',
        name: 'SignupConfmCode',
        component: () => import(/* webpackChunkName: "otp" */ '../components/sign/ConfirmCode.vue')
      },
      {
        path:'/sign/repw',
        name: 'RePw',
        component: () => import(/* webpackChunkName: "sign" */ '../components/sign/RePassword.vue')
      },
      {
        path:'/sign/certification',
        name: 'Certification',
        component: () => import(/* webpackChunkName: "certification" */ '../components/sign/Certification.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition){
    return {x:0, y:0}
  },
  routes
})

export default router
