import Vue from "vue";
import Vuex from "vuex";
import {i18n} from '@/locales/i18n'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobile_menu: false,
    token: "",
    id: "",
    login: false,
    plan: "",
    e_date: "",
    botDetails: [],
    locale: i18n.locale
  },
  plugins: [createPersistedState()],
  mutations: {
    open_menu(state, tf) {
      state.mobile_menu = tf;
    },
    Login(state, info) {
      state.id = info.id;
      state.token = info.token;
      state.plan = info.plan;
      state.e_date = info.e_date;
      state.login = true;
    },
    Logout(state) {
      state.id = "";
      state.token = "";
      state.plan = "";
      state.e_date = "";
      state.login = false;
    },
    getBotDetails(state, data) {
      state.botDetails = [data, ...state.botDetails];
    },
    clearBotDetails(state) {
      state.botDetails = [];
    },
    updateLocale(state, newLocale) {
      state.locale = newLocale
    }
  },
  actions: {
    SETLOGOUT({ commit }) {
      commit("Logout");
    },
    SETLOGIN({ commit }, info) {
      commit("Login", info);
    },
    getBotDetails({ commit }, data) {
      commit("getBotDetails", data);
    },
    clearBotDetails({ commit }) {
      commit("clearBotDetails");
    },
    changeLocale({ commit }, newValue) {
      // update it in i18n plugin
      i18n.locale = newValue
      commit('updateLocale', newValue)
    }
  },
  modules: {},
});
